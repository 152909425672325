import React from 'react';
import { InStreamAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

interface SimpleInStreamProps {
  id: number;
  pageType?: string;
}

const SimpleInStream: React.FC<SimpleInStreamProps> = ({ id, pageType }: SimpleInStreamProps) => (
  <InStreamAd
    className='simple'
    $pageType={pageType}
  >
    <AdSlotToggle
      placementName='SimpleInStream'
      index={id + 1}
      slotId={`SimpleInStream${id}`}
    />
  </InStreamAd>
);

export default SimpleInStream;
